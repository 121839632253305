import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Text = makeShortcode("Text");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Text sx={{
      fontSize: [4, 5, 6],
      fontWeight: `bold,italic`,
      color: `green`
    }} mdxType="Text">
  Capital
    </Text>
    <p><strong parentName="p">{` Rule 1`}</strong>{` : `}<em parentName="p">{`Never Lose Money`}</em></p>
    <Text sx={{
      fontSize: [4, 5, 6],
      fontWeight: `italic`,
      color: `bold`
    }} mdxType="Text">
  Alpha-bet
    </Text>
    <p>{` `}<strong parentName="p">{` Rule 2`}</strong>{` :`}<em parentName="p">{`Only bet on no-brainer's likely to beat index in long term, Never forget`}</em>{` `}<strong parentName="p">{`Rule 1!`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      